import { getAnalyticsFlightsAndAppSettings } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';
import type { AriaDatapointType, DatapointStatus } from 'owa-analytics-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { getItem, setItem } from 'owa-local-storage';
import { getThreadName } from 'owa-thread-config';

const DatapointHistoryMaxLengthStorageKey = 'DatapointTracingMaxHistoryLength';
const DefaultDatapointHistoryMaxLength = 1000;

// Store value in memory so we don't query localstorage every time we push a datapoint
let DatapointHistoryMaxLength: number | undefined = undefined;

type DatapointInfo = {
    datapoint: AriaDatapointType;
    status: DatapointStatus | undefined;
};

const datapointInfo: DatapointInfo[] = [];

export function logToTracingPanel(datapoint: AriaDatapointType, status?: DatapointStatus) {
    if (
        getThreadName() === 'MAIN_THREAD' && // Only add events generated on the main thread
        getAnalyticsFlightsAndAppSettings()?.isDiagDatapointTracingPanelEnabled
    ) {
        if (datapointInfo.length > getDatapointHistoryMaxLength()) {
            setTimeout(() => {
                datapointInfo.splice(0, getDatapointHistoryMaxLength() / 2);
            }, 0);
        }

        datapointInfo.push({ datapoint, status });
    }
}

export function getDatapointInfo() {
    return datapointInfo;
}

export function getDatapointHistoryMaxLength() {
    if (DatapointHistoryMaxLength != undefined) {
        return DatapointHistoryMaxLength;
    }

    return (DatapointHistoryMaxLength =
        parseInt(getItem(self, DatapointHistoryMaxLengthStorageKey) || '') ||
        DefaultDatapointHistoryMaxLength);
}

export function onHistoryMaxLengthChanged(_event: any, newValue: string | undefined) {
    const parsedValue = parseInt(newValue || '');

    if (!isNaN(parsedValue) && parsedValue > 0) {
        setItem(self, DatapointHistoryMaxLengthStorageKey, parsedValue.toString());
        DatapointHistoryMaxLength = parsedValue;
    }
}
